import entypo from '~/resources/icons/entypo-sprite.svg';
import type { IconProps } from './types';

function Icon({
	className = '', name, pathToSprite = entypo, title = '', ...rest
}: IconProps) {
	return (
		<svg
			className={className}
			{...rest} // eslint-disable-line react/jsx-props-no-spreading
		>
			{title ? (<title>{title}</title>) : null}
			<use xlinkHref={`${pathToSprite}#${name}`} />
		</svg>
	);
}

export { Icon as default, Icon };
